<template>
    <v-row cols="12">
        <v-card
            elevation="5"
            class="pb-15 w-100 border-radius-1 elevation-1"
            outlined
            :loading="loading"
        >
            <v-row>
                <v-col class="d-flex justify-center align-center pa-0 py-1 mt-3" cols="2">
                    <v-btn tile color="primary" text class="text-capitalize" @click="$router.go(-1)"
                        ><v-icon class="mr-2">mdi-arrow-top-left</v-icon>{{ $t('go_back') }}</v-btn
                    >
                </v-col>
            </v-row>
            <v-row class="px-7">
                <v-col cols="12">
                    <v-row class="px-0 justify-center align-center">
                        <v-col cols="6" class="pa-0">
                            <v-card-title class="text-h5 font-weight-bold primary--text py-1">{{
                                $t('reporting.top_student_full_list')
                            }}</v-card-title>
                        </v-col>
                        <v-col cols="4" class="pa-0 ml-auto">
                            <BeeTabs
                                @changeTab="changeTab"
                                :items="days"
                                :tabSize="4"
                                textAlign="left"
                                :flat="false"
                                :disabled="loading"
                                :tabSelected="2"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <div class="d-flex justify-center align-center flex-column">
                                <v-select
                                    :label="$t('choose_course')"
                                    item-value="id"
                                    item-text="name"
                                    :items="coursesWithGlobal"
                                    v-model="currentClass"
                                    dense
                                    outlined
                                    flat
                                    hide-details
                                    color="primary"
                                    @change="changeData"
                                    :disabled="loading"
                                ></v-select>
                                <v-select
                                    :label="$t('choose_tier')"
                                    :items="tierItems"
                                    v-model="currentDistinct"
                                    dense
                                    outlined
                                    flat
                                    hide-details
                                    :color="selectColor"
                                    class="mt-4"
                                    @change="selectData"
                                    :disabled="loading"
                                ></v-select>
                            </div>
                        </v-col>
                        <v-col cols="10">
                            <AccuracyTable
                                :table-data="tableData"
                                :main-color="selectColor"
                                :hide-actions="true"
                                short
                            ></AccuracyTable>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import AccuracyTable from '../../library/core/AccuracyTable'
import courseBookStats from '../../../services/courseBookStats'
import BeeTabs from '../../core/BeeTabs'

export default {
    name: 'TopStudents',
    props: {
        courseId: { type: [Number, String], default: 0 },
    },
    components: {
        BeeTabs,
        AccuracyTable,
    },
    async mounted() {
        this.loading = true
        this.currentClass = parseInt(this.courseId)
        this.fullData = await this.getData()
        this.changeTab(this.currentTab)
        this.loading = false
    },
    data: () => ({
        currentClass: null,
        currentDistinct: 0,
        currentTab: 2,
        loading: false,
        tableData: [],
        fullData: null,
    }),
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            user: (state) => state.userData.user,
        }),
        selectColor() {
            switch (this.currentDistinct) {
                case 0:
                    return '#ff6f3a'
                case 1:
                    return '#24dca9'
                case 2:
                    return '#a48b00'
                case 3:
                    return '#514773'
            }
            return 'primary'
        },
        days() {
            return [
                this.$t('stats.seven_days'),
                this.$t('stats.thirty_days'),
                this.$t('stats.ninety_days'),
            ]
        },
        coursesWithGlobal() {
            return [
                ...[{ id: 0, name: this.$t('global') }],
                ...this.courses.map((i) => ({ id: i.id, name: i.name })),
            ]
        },
        tierItems() {
            return [
                {
                    text: this.$t('global'),
                    value: 0,
                },
                {
                    text: this.$t('reporting.tier_text[0]'),
                    value: 1,
                },
                {
                    text: this.$t('reporting.tier_text[1]'),
                    value: 2,
                },
                {
                    text: this.$t('reporting.tier_text[2]'),
                    value: 3,
                },
            ]
        },
    },
    methods: {
        async getData() {
            const response = await courseBookStats
                .getFullAccuracyData(this.currentClass, this.user.id)
                .catch((e) => console.error(e))
            return response.content
        },
        async changeData() {
            this.loading = true
            this.fullData = await this.getData()
            this.changeTab(this.currentTab)
            this.loading = false
        },
        changeTab(tab) {
            this.currentTab = tab
            const { day7, day30, day90 } = this.fullData
            switch (this.currentTab) {
                case 0:
                    this.tableData = day7
                    break
                case 1:
                    this.tableData = day30
                    break
                case 2:
                    this.tableData = day90
                    break
            }
        },
        selectData() {
            switch (this.currentDistinct) {
                case 0:
                    this.changeTab(this.currentTab)
                    break
                case 1:
                    this.changeTab(this.currentTab)
                    this.tableData = this.filterArrayValue(this.tableData, 61)
                    break
                case 2:
                    this.changeTab(this.currentTab)
                    this.tableData = this.filterArrayValue(this.tableData, 41, 60)
                    break
                case 3:
                    this.changeTab(this.currentTab)
                    this.tableData = this.filterArrayValue(this.tableData, 0, 40)
                    break
            }
        },
        filterArrayValue(arr = [], valueFrom = 0, valueTo = 100) {
            return arr.filter((i) => i.accuracy >= valueFrom && i.accuracy <= valueTo)
        },
    },
}
</script>

<style scoped></style>
